import React from 'react';
import { DataLink, SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description =
  'Here is the contact information for the Disability Terminology Project';

const keywords = ['contact', 'email', 'website'];

const Contact = () => (
  <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
    {updateMetaInfo(description, keywords)}
    <div className="container">
      <SectionTitle title="Contact Me" />
      <div className="row">
        <div className="col-lg-12">
          <div className="mi-contact-infoblock-content">
            <p>
              This project is currently led by{' '}
              <DataLink to="https://athersharif.me">Ather Sharif</DataLink>. For
              any questions or concerns regarding this project, please feel free
              to reach out to Ather at{' '}
              <DataLink to="mailto:ather@cs.washington.edu">
                ather at cs dot washington dot edu
              </DataLink>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
