import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from './Footer';
import DataLink from './DataLink';

const Sidebar = ({ toggler }) => {
  return (
    <div className="mi-header-inner">
      <div className="mi-header-image">Disability Terminology Project</div>
      <ul className="mi-header-menu" onClick={toggler}>
        <li>
          <NavLink exact to="/">
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/identity-first-language">
            <span>Identity-First Language</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/person-first-language">
            <span>Person-First Language</span>
          </NavLink>
        </li>
        <li>
          <DataLink to="https://bit.ly/disability-language-survey">
            <span>Share Your Preference</span>
          </DataLink>
        </li>
        <li>
          <NavLink to="/contact">
            <span>Contact</span>
          </NavLink>
        </li>
      </ul>
      <Footer />
    </div>
  );
};

Sidebar.propTypes = {
  toggler: PropTypes.func
};

export default Sidebar;
