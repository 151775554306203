import React from 'react';
import capitalize from 'lodash/capitalize';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';

const getAgeGroup = age => {
  let index = allAges.length - 1;

  if (age >= 18 && age <= 25) index = 0;
  else if (age > 25 && age <= 35) index = 1;
  else if (age > 35 && age <= 50) index = 2;
  else if (age > 50 && age <= 60) index = 3;
  else if (age > 60 && age <= 70) index = 4;

  return allAges[index];
};

const altTextSettings = {
  overall: {
    chartType: 'pie',
    text: ''
  },
  disability: {
    chartType: 'bar',
    text: 'per disability',
    beforeKey: 'people with',
    beforeValue: 'disabilities prefer'
  },
  ageGroup: {
    chartType: 'bar',
    text: 'per age group',
    beforeKey: 'people in age group',
    beforeValue: 'prefer'
  },
  gender: {
    chartType: 'bar',
    text: 'per gender',
    beforeKey: 'people who identify as',
    beforeValue: 'prefer'
  },
  country: {
    chartType: 'bar',
    text: 'per country',
    beforeKey: 'people from',
    beforeValue: 'prefer'
  },
  year: {
    chartType: 'line',
    text: 'per year',
    beforeKey: 'in',
    beforeValue: 'people prefered'
  }
};

const abbreviations = {
  IFL: 'identity-first language',
  PFL: 'person-first language',
  NP: 'no preference'
};

const formatText = text => {
  if (!Array.isArray(text)) text = [text];

  return text.map(t => capitalize(t).trim()).join(', ') + '.';
};

const getSummary = (data, tab) => {
  const { beforeKey, beforeValue, chartType } = altTextSettings[tab];
  let summary = '';

  if (chartType === 'pie') {
    const max = maxBy(data, 'value');

    if (max) summary = ['overall, people prefer ' + abbreviations[max.name]];
  } else {
    summary = data.map(d => {
      const max = maxBy(d.values, 'groupValue');

      if (max)
        return (
          beforeKey +
          ' ' +
          d.key.split('(')[0].trim() +
          ' ' +
          beforeValue +
          ' ' +
          abbreviations[max.groupName]
        );

      return '';
    });
  }

  return formatText(summary);
};

export const allDisabilities = [
  'Mobility',
  'Visual',
  'Cognitive',
  'Learning',
  'Neurological',
  'Auditory',
  'Chronic Illness',
  'Mental Health Related',
  'Other'
];

export const allGenders = [
  'Man',
  'Woman',
  'Non-binary',
  'Prefer not to disclose'
];

export const allAges = [
  '18-25',
  '26-35',
  '36-50',
  '51-60',
  '61-70',
  '71 and older'
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const mapDataKeys = data =>
  data.map(d => {
    const disabilityResponse = d['How would you describe your disability?']
      .replace(/ *\([^)]*\) */g, '')
      .replace(/-/g, ' ')
      .toLowerCase();
    const disability = allDisabilities
      .filter(d => disabilityResponse.includes(d.toLowerCase()))
      .join(',');

    const preferenceEncounter =
      d[
        'In your daily life, which style of language do you encounter the most? '
      ];

    const preferenceResponse =
      d['Do you prefer person-first or identify-first language?'];
    let preference = 'NP';

    if (preferenceResponse === 'Person-first language') preference = 'PFL';
    else if (preferenceResponse === 'Identity-first language')
      preference = 'IFL';

    let genderResponse = d['What is your gender?'];

    if (genderResponse === 'Prefer to self-describe')
      genderResponse = 'Non-binary';

    const gender = uniq(
      allGenders
        .filter(g => genderResponse.includes(g))
        .map(g => {
          if (g === 'Prefer to self-describe') return 'Non-binary';
          else return g;
        })
    ).join(',');

    const age = parseInt(d['What is your age? (Please provide a number)']);

    const date = d['Timestamp'].split(' ')[0].split('/');
    const month = months[parseInt(date[0]) - 1];
    const year = parseInt(date[2]);

    return {
      age,
      ageGroup: getAgeGroup(age),
      preference,
      preferenceEncounter,
      disability: disability !== '' ? disability : 'Other',
      year: month + ' ' + year,
      familiarity:
        d[
          'Were you familiar with the terms “person-first language” and “identity-first language” before taking this survey?'
        ] === 'Yes',
      diagnosisAge:
        d[
          'At what age were you diagnosed? (Please provide a number. If at birth, type 0. If you are unsure, leave this blank.)'
        ],
      gender,
      pronouns: d['What pronouns do you use?'],
      country: d['Which country do you reside in?']
    };
  });

export const convertDataToTable = (data, tab) => {
  const table = {
    head: [],
    body: []
  };
  const { chartType } = altTextSettings[tab];

  if (chartType !== 'pie') {
    table.head = [startCase(tab)];
    data.forEach(d => {
      d.values.forEach(v => {
        table.body = [
          ...table.body,
          {
            factor: d.key.split('(')[0].trim(),
            preference: v.groupName,
            count: v.groupValue
          }
        ];
      });
    });
  } else {
    data.forEach(d => {
      table.body = [
        ...table.body,
        {
          preference: d.name,
          count: d.value
        }
      ];
    });
  }

  table.head = [...table.head, 'Preference', 'Number of Responses'];

  return (
    <table>
      <thead>
        <tr>
          {table.head.map(h => (
            <th scope="col" key={h}>
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.body.map((b, i) => (
          <tr key={b.preference + '-' + i}>
            {Object.keys(b).length > 2 && <td>{b.factor}</td>}
            <td>{b.preference}</td>
            <td>{b.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const getAltText = (data, tab) => {
  const { chartType, text } = altTextSettings[tab];
  const summary = getSummary(data, tab);
  const title = formatText(
    `${chartType} chart showing counts for identity-first, person-first, and no preference ${text}`
  );
  const dataTableText =
    chartType !== 'pie' && summary.length > 0
      ? 'data table is presented below'
      : null;

  return `${title}${summary && summary.length > 0 ? ' ' + summary : ''}${
    dataTableText ? ' ' + formatText(dataTableText) : ''
  }`;
};

export const orderByYear = data => {
  data = orderBy(
    data.map(d => {
      const [month, year] = d.key.split(' ');
      const timestamp = new Date(
        parseInt(year),
        months.indexOf(month)
      ).getTime();

      return { ...d, timestamp };
    }),
    ['timestamp'],
    ['asc']
  );

  return data.map(d => ({
    key: d.key,
    values: d.values
  }));
};
