import * as d3 from 'd3';
import max from 'lodash/max';

const createD3 = (data, { colors }) => {
  const container = document.getElementById('chart');
  const maxXLabel = max(data.map(d => d.key.toString().length));
  const margin = { top: 20, right: 40, bottom: maxXLabel * 5 + 20, left: 70 };
  const height = 500 - margin.top - 20;
  const width = container.offsetWidth - margin.left - margin.right;

  const transform = margin.left + ',' + margin.top;

  const svg = d3
    .select('#chart')
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + 40 + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + transform + ')');

  const x0 = d3
    .scaleBand()
    .rangeRound([0, width], 0.5)
    .paddingInner(0.1);
  const x1 = d3.scaleBand().padding(0.05);
  const y = d3.scaleLinear().rangeRound([height, 0]);

  const xAxis = d3
    .axisBottom()
    .scale(x0)
    .tickValues(data.map(d => d.key));

  const yAxis = d3.axisLeft().scale(y);

  const color = d3.scaleOrdinal().range(colors);

  const categoriesNames = data.map(d => d.key);
  const rateNames = data[0].values.map(d => d.groupName);

  const make_x_gridlines = () => d3.axisBottom(x0).ticks(10);
  const make_y_gridlines = () => d3.axisLeft(y).ticks(10);

  x0.domain(categoriesNames);
  x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);
  y.domain([0, d3.max(data, key => d3.max(key.values, d => d.groupValue))]);

  svg
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxis)
    .selectAll('text')
    .attr('y', 0)
    .attr('x', 9)
    .attr('dy', '.35em')
    .attr('transform', 'rotate(90)')
    .style('font-size', '14px')
    .style('text-anchor', 'start')
    .style('opacity', 1);

  svg
    .append('g')
    .attr('class', 'y axis')
    .style('opacity', '0')
    .call(yAxis)
    .append('text')
    .attr('transform', 'rotate(-270)')
    .attr('x', height / 2)
    .attr('y', 6)
    .attr('dy', '3em')
    .style('font-size', '14px')
    .style('text-anchor', 'middle')
    .style('font-weight', 'bold')
    .style('fill', 'black')
    .text('Number of Responses');

  svg.select('.y').style('opacity', '1');

  svg
    .append('g')
    .attr('class', 'grid')
    .attr('transform', 'translate(0,' + height + ')')
    .call(
      make_x_gridlines()
        .tickSize(-height)
        .tickFormat('')
    );

  svg
    .append('g')
    .attr('class', 'grid')
    .call(
      make_y_gridlines()
        .tickSize(-width)
        .tickFormat('')
    );

  const slice = svg
    .selectAll('.slice')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'g')
    .attr('transform', d => 'translate(' + x0(d.key) + ',0)');

  slice
    .selectAll('rect')
    .data(d => d.values)
    .enter()
    .append('rect')
    .attr('width', x1.bandwidth())
    .attr('x', d => x1(d.groupName))
    .style('fill', d => color(d.groupName))
    .attr('y', () => y(0))
    .attr('height', () => height - y(0));

  slice
    .selectAll('rect')
    .attr('y', d => y(d.groupValue))
    .attr('height', d => height - y(d.groupValue));

  //Legend
  const legend = svg
    .selectAll('.legend')
    .data(data[0].values.map(d => d.groupName).reverse())
    .enter()
    .append('g')
    .attr('class', 'legend')
    .attr('transform', (d, i) => 'translate(0,' + i * 20 + ')')
    .style('opacity', '0');

  legend
    .append('rect')
    .attr('x', width - 18)
    .attr('width', 18)
    .attr('height', 18)
    .style('fill', d => color(d));

  legend
    .append('text')
    .attr('x', width - 24)
    .attr('y', 9)
    .attr('dy', '.35em')
    .style('text-anchor', 'end')
    .text(d => d);

  legend.style('opacity', '1');
};

export default createD3;
