import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withGoogleSheets } from 'react-db-google-sheets';
import countBy from 'lodash/countBy';
import uniq from 'lodash/uniq';
import flowRight from 'lodash/flowRight';
import Select from 'react-select';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import createBarChart from './createBarChart';
import createLineChart from './createLineChart';
import createPieChart from './createPieChart';
import {
  allAges,
  allDisabilities,
  allGenders,
  convertDataToTable,
  getAltText,
  mapDataKeys,
  orderByYear
} from '../../helpers';

import './index.css';

const responses = ['IFL', 'PFL', 'NP'];

const mapToSelectOption = values =>
  values && values.length > 0
    ? values.map(v => ({ label: v, value: v }))
    : null;

class Home extends Component {
  static propTypes = {
    db: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object)
    }),
    history: PropTypes.shape({
      push: PropTypes.func
    }),
    location: PropTypes.shape({
      hash: PropTypes.string
    })
  };

  constructor(props) {
    super(props);
    this.data = mapDataKeys(props.db.data);
    this.state = this.initialState;
  }

  getCurrentTab = () => {
    const { location } = this.props;

    return location.hash && location.hash !== ''
      ? location.hash.replace('#', '')
      : 'overall';
  };

  initialState = {
    age: [],
    country: [],
    disability: [],
    gender: [],
    tabKey: this.getCurrentTab(),
    year: []
  };

  componentDidMount() {
    this.createChart();
  }

  componentDidUpdate() {
    this.createChart();
  }

  createChart = () => {
    const { tabKey } = this.state;
    const chartContainer = document.getElementById('chart');

    let createChart = createPieChart;

    if (tabKey !== 'overall') createChart = createBarChart;
    if (tabKey === 'year') createChart = createLineChart;

    if (chartContainer) {
      chartContainer.innerHTML = '';

      const data = this.getData();
      const total = data.map(d => d.value || 0).reduce((a, b) => a + b);

      if (tabKey !== 'overall' || total > 0) {
        return createChart(data, {
          name: d => d.name,
          value: d => d.value,
          colors: ['#1b9e77', '#d95f02', '#7570b3']
        });
      } else {
        chartContainer.innerHTML =
          '<p>No data found for the parameters specified. Please adjust filtering options.</p>';
      }
    } else {
      this.createChart();
    }
  };

  getData = (raw = false) => {
    const { age, country, disability, gender, tabKey, year } = this.state;
    let data = this.data;

    if (tabKey === 'overall') {
      if (age.length > 0) {
        data = data.filter(d => age.includes(d.ageGroup));
      }

      if (country.length > 0) {
        data = data.filter(d => country.includes(d.country));
      }

      if (disability.length > 0) {
        data = data.filter(d =>
          disability.some(a => d.disability.split(',').includes(a))
        );
      }

      if (gender.length > 0) {
        data = data.filter(d =>
          gender.some(g => d.gender.split(',').includes(g))
        );
      }

      if (year.length > 0) {
        data = data.filter(d => year.some(y => d.year.toString().includes(y)));
      }

      if (raw) return data;

      const preferencesByCount = countBy(data, d => d.preference);

      return responses.map(d => ({
        name: d,
        value: preferencesByCount[d]
      }));
    } else {
      let keys = [];

      if (tabKey === 'disability') keys = allDisabilities;
      else if (tabKey === 'ageGroup') keys = allAges;
      else if (tabKey === 'gender') keys = allGenders;
      else keys = data.map(d => d[tabKey]);

      data = uniq(keys).map(key => {
        const filteredData = data.filter(d =>
          d[tabKey].toString().includes(key)
        );
        const preferencesByCount = countBy(filteredData, d => d.preference);

        return {
          key: key + ' (' + filteredData.length + ')',
          values: responses.map(p => ({
            groupName: p,
            groupValue: preferencesByCount[p] || 0
          }))
        };
      });

      if (tabKey === 'year') {
        data = orderByYear(data);
      }

      return data;
    }
  };

  onChange = (data, key) => {
    this.setState({
      [key]: data.map(d => d.value)
    });
  };

  getSelectOptions = (key, data) => {
    if (data) {
      return data.map(d => ({
        value: d,
        label: d
      }));
    } else {
      return uniq(this.data.map(d => d[key])).map(d => ({
        value: d,
        label: d
      }));
    }
  };

  render() {
    const { age, country, disability, gender, year, tabKey } = this.state;

    const ages = this.getSelectOptions('ageGroup', allAges);
    const countries = this.getSelectOptions('country');
    const disabilities = this.getSelectOptions('disability', allDisabilities);
    const genders = this.getSelectOptions('gender', allGenders);
    const years = this.getSelectOptions('year');

    const data = this.getData();

    return (
      <div className="mi-home-area mi-padding-section">
        <div className="mi-home-content">
          <h1>Identity-First Language (IFL) vs. Person-First Language (PFL)</h1>
          <Tabs
            activeKey={tabKey}
            defaultActiveKey="overall"
            onSelect={tabKey => {
              this.props.history.push('#' + tabKey);
              this.setState({ tabKey });
            }}
            className="tabs nav-fill nav-justify"
          >
            <Tab eventKey="overall" title="Overall">
              <div className="row justify-content-center select-row">
                <div className="col-lg-4 col-sm-12">
                  <Select
                    options={ages}
                    isMulti
                    onChange={d => this.onChange(d, 'age')}
                    placeholder="Select Age Group..."
                    value={mapToSelectOption(age)}
                    isOptionDisabled={d => d.label.includes('(0)')}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Select
                    options={countries}
                    isMulti
                    onChange={d => this.onChange(d, 'country')}
                    placeholder="Select Country..."
                    value={mapToSelectOption(country)}
                    isOptionDisabled={d => d.label.includes('(0)')}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Select
                    options={disabilities}
                    isMulti
                    onChange={d => this.onChange(d, 'disability')}
                    placeholder="Select Disability..."
                    value={mapToSelectOption(disability)}
                    isOptionDisabled={d => d.label.includes('(0)')}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Select
                    options={genders}
                    isMulti
                    onChange={d => this.onChange(d, 'gender')}
                    placeholder="Select Gender..."
                    value={mapToSelectOption(gender)}
                    isOptionDisabled={d => d.label.includes('(0)')}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Select
                    options={years}
                    isMulti
                    onChange={d => this.onChange(d, 'year')}
                    placeholder="Select Month/Year..."
                    value={mapToSelectOption(year)}
                    isOptionDisabled={d => d.label.includes('(0)')}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Button
                    variant="outline-primary"
                    onClick={() => this.setState(this.initialState)}
                    className="reset-button"
                  >
                    Clear Selections
                  </Button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="disability" title="By Disability" />
            <Tab eventKey="ageGroup" title="By Age Group" />
            <Tab eventKey="gender" title="By Gender" />
            <Tab eventKey="country" title="By Country" />
            <Tab eventKey="year" title="By Year" />
          </Tabs>
          <div className="abbreviations">
            <strong>PFL</strong>=Person-First Language, <strong>IFL</strong>
            =Identity-First Language, <strong>NP</strong>=No Preference
          </div>
          <div id="chart" tabIndex="0" aria-hidden={true} />
          <div id="chart-sr" className="sr-only" tabIndex="0">
            {getAltText(data, tabKey)}
            {convertDataToTable(data, tabKey)}
          </div>
        </div>
      </div>
    );
  }
}

export default flowRight(withRouter, withGoogleSheets('data'))(Home);
