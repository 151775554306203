import React from 'react';
import { DataLink, SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description = 'Description of Person-First Language';

const keywords = ['pfl', 'person-first', 'language', 'term'];

const Contact = () => (
  <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
    {updateMetaInfo(description, keywords)}
    <div className="container">
      <SectionTitle title="Person-First Language (PFL)" />
      <div className="row">
        <div className="col-lg-12">
          <div className="mi-contact-infoblock-content">
            <p>
              Person-first language mentions the person first and the disability
              second. Examples for person-first language include &quot;person
              who is blind,&quot; &quot;person who uses a wheelchair,&quot; and
              &quot;person who has autism.&quot; Read more about PFL{' '}
              <DataLink to="https://odr.dc.gov/page/people-first-language">
                here
              </DataLink>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
