import React from 'react';
import { Switch, Route } from 'react-router';
import ReactGA from 'react-ga';
import { Contact, Home, IFL, NoMatch, PFL } from './views';

const componentWithGA = Component => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return <Component />;
};

const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => componentWithGA(Home)} />
    <Route exact path="/contact" render={() => componentWithGA(Contact)} />
    <Route
      exact
      path="/identity-first-language"
      render={() => componentWithGA(IFL)}
    />
    <Route
      exact
      path="/person-first-language"
      render={() => componentWithGA(PFL)}
    />
    <Route component={NoMatch} />
  </Switch>
);

export default Routes;
