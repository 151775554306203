import * as d3 from 'd3';
import max from 'lodash/max';
import round from 'lodash/round';
import sum from 'lodash/sum';
import { months } from '../../helpers';

const createD3 = (rawData, { colors }) => {
  const data = [];
  const parseDate = d3.timeParse('%Y-%m');

  rawData.forEach(d => {
    const [month, year] = d.key.split(' ');
    const date = new Date(parseInt(year), months.indexOf(month));

    const total = sum(d.values.map(v => v.groupValue));

    data.push({
      date: parseDate(date.getFullYear() + '-' + (date.getMonth() + 1)),
      key: d.key,
      IFL: round(
        (d.values.find(v => v.groupName === 'IFL').groupValue / total) * 100,
        2
      ),
      PFL: round(
        (d.values.find(v => v.groupName === 'PFL').groupValue / total) * 100,
        2
      ),
      NP: round(
        (d.values.find(v => v.groupName === 'NP').groupValue / total) * 100,
        2
      )
    });
  });

  const container = document.getElementById('chart');
  const maxXLabel = max(data.map(d => d.key.toString().length));
  const margin = { top: 20, right: 40, bottom: maxXLabel * 5 + 10, left: 70 };
  const height = 500 - margin.top - 20;
  const width = container.offsetWidth - margin.left - margin.right;

  const transform = margin.left + ',' + margin.top;

  const svg = d3
    .select('#chart')
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + 40 + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + transform + ')');

  const x = d3.scaleTime().range([0, width]);
  const y = d3.scaleLinear().range([height, 0]);

  const xAxis = d3
    .axisBottom()
    .scale(x)
    .tickValues(data.map(d => d.date))
    .tickFormat(d => data.find(e => e.date === d).key);
  const yAxis = d3.axisLeft().scale(y);

  const color = d3.scaleOrdinal().range(colors);

  const line = d3
    .line()
    .curve(d3.curveMonotoneX)
    .x(d => x(d['date']))
    .y(d => y(d['preference']));

  const categoriesNames = Object.keys(data[0]).filter(
    d => d !== 'date' && d !== 'key'
  );

  const preferences = categoriesNames.map(c => ({
    category: c,
    datapoints: data.map(d => ({ date: d.date, preference: +d[c] }))
  }));

  const make_x_gridlines = () => d3.axisBottom(x).ticks(10);
  const make_y_gridlines = () => d3.axisLeft(y).ticks(10);

  x.domain(d3.extent(data, d => d.date));
  y.domain([0, 100]);
  color.domain(categoriesNames);

  svg
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxis)
    .selectAll('text')
    .attr('y', 0)
    .attr('x', 9)
    .attr('dy', '.35em')
    .attr('transform', 'rotate(90)')
    .style('font-size', '14px')
    .style('text-anchor', 'start')
    .style('font-weight', 'bold')
    .style('opacity', 1);

  svg
    .append('g')
    .attr('class', 'y axis')
    .style('opacity', '0')
    .call(yAxis)
    .append('text')
    .attr('transform', 'rotate(-270)')
    .attr('x', height / 2)
    .attr('y', 6)
    .attr('dy', '3em')
    .style('font-size', '14px')
    .style('text-anchor', 'middle')
    .style('font-weight', 'bold')
    .style('fill', 'black')
    .text('Percentage of Responses');

  svg.select('.y').style('opacity', '1');

  svg
    .append('g')
    .attr('class', 'grid')
    .attr('transform', 'translate(0,' + height + ')')
    .call(
      make_x_gridlines()
        .tickSize(-height)
        .tickFormat('')
    );

  svg
    .append('g')
    .attr('class', 'grid')
    .call(
      make_y_gridlines()
        .tickSize(-width)
        .tickFormat('')
    );

  const categories = svg
    .selectAll('.category')
    .data(preferences)
    .enter()
    .append('g')
    .attr('class', 'category');

  categories
    .append('path')
    .attr('class', 'line')
    .attr('d', d => line(d.datapoints))
    .style('stroke', d => color(d.category));

  //Legend
  const legend = svg
    .selectAll('.legend')
    .data(categoriesNames)
    .enter()
    .append('g')
    .attr('class', 'legend')
    .attr('transform', (d, i) => 'translate(0,' + i * 20 + ')')
    .style('opacity', '0');

  legend
    .append('rect')
    .attr('x', width - 18)
    .attr('width', 18)
    .attr('height', 18)
    .style('fill', d => color(d));

  legend
    .append('text')
    .attr('x', width - 24)
    .attr('y', 9)
    .attr('dy', '.35em')
    .style('text-anchor', 'end')
    .text(d => d);

  legend.style('opacity', '1');
};

export default createD3;
